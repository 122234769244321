import React, { useState, useRef } from 'react';
import { navigate } from 'gatsby';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';

import InputField from '~/components/atoms/InputField';
import SelectField from '~/components/atoms/SelectField';
import AutocompleteInputField from '~/components/atoms/AutocompleteInputField';
import IncrementorInputField from '~/components/atoms/IncrementorInputField';
import CheckboxField from '~/components/atoms/CheckboxField';
import Button from '~/components/atoms/Button';
import useFormSubmit from '~/hooks/useFormSubmit';

const VisuallyHiddenField = styled.div`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  pointer-events: none;
`;

const validationSchema = Yup.object({
  firstname: Yup.string().required('Required'),
  lastname: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email address').required('Required'),
  phone: Yup.string().required('Required'),
  property_postcode: Yup.string().required('Required'),
  number_of_bedrooms: Yup.string().required('Required'),
  privacy: Yup.boolean().oneOf([true], 'This field must be checked'),
  spam: Yup.boolean().oneOf([false], ''),
});

export default function PricingForm2({ setIsSubmitted, className, postcodeParameter }) {
  // console.log('~~~PricingForm2');

  const [submitting, setSubmitting] = useState(false)

  const [errorMessage, setErrorMessage] = useState('');
  const { post, response } = useFormSubmit({
    portalId: '7026924',
    formId: 'd1610fce-cfb1-4caf-b09c-b109574720d2',
    setIsSubmitted,
  });

  const getEstimate = async ({ property_postcode, bedrooms }) => {
    if (!property_postcode) return false;

    try {
      const res = await fetch(
        `https://api.airdna.co/client/v1/rentalizer/estimate?access_token=ff6001b095364ed29d9a0f15b0679aaa&zipcode=${encodeURIComponent(
          property_postcode
        )}&bedrooms=${bedrooms}`
      );

      // let newApiURL = "https://api.airdna.co/api/enterprise/v2/rentalizer/estimate?access_token=b7e0965b8e20407bbe3f9bd7cf200d2f";
      // const res = await fetch(`${newApiURL}&zipcode=${encodeURIComponent(property_postcode)}&bedrooms=${bedrooms}`);


      const result = await res.json();
      const val = ((result?.property_stats?.revenue?.ltm / 12) * (1 - 0.15)) / 1.3;
      return Math.ceil(val);
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  return (
    <div className={`px-8 py-12 md:py-16 md:px-12 bg-white relative ${className}`}>
      <Formik
        initialValues={{
          firstname: '',
          lastname: '',
          email: '',
          phone: '',
          property_postcode: postcodeParameter,
          number_of_bedrooms: '',
          privacy: false,
          spam: false,
          gdpr_check_box_text: true
        }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          setSubmitting(true)
          // Get rental estimate
          const estimate = await getEstimate({
            bedrooms: values.number_of_bedrooms,
            property_postcode: values.property_postcode
          });

          // Post to hubspot
          delete values['privacy'];
          delete values['spam'];
          delete values['number_of_bedrooms'];

          await post(values);

          if (response?.data?.status === 'error') {
            const errorHeading = response?.data?.message;
            const messages = response.data.errors.map(({ message }) => message).join('<br/>');
            setErrorMessage(errorHeading + '<br /><br />' + messages);
          } else {
            navigate('/pricing/result/', {
              state: {
                ...values,
                estimate,
              },
            });
          }
        }}
      >
        {(props) => (
          <Form id="pricing-form">
            <div className="grid grid-cols-1 gap-y-8 md:grid-cols-2 md:gap-y-14 md:gap-x-8 mb-14">
              <InputField divclassname="col-span-1 md:col-span-1 " name="firstname" type="text" label="Your first name" />
              <InputField divclassname="col-span-1 md:col-span-1 " name="lastname" type="text" label="Your last name" />
              <InputField divclassname="col-span-1 md:col-span-1 " name="email" type="email" label="Your email address" />
              <InputField divclassname="col-span-1 md:col-span-1 " name="phone" type="text" label="Your phone number" />
              <InputField divclassname="col-span-1 md:col-span-1 " name="property_postcode" type="text" label="Postcode" defaultValue={true} />
              <SelectField
                divclassname="col-span-1 md:col-span-1 "
                name="number_of_bedrooms"
                type="text"
                label="No. of bedrooms:"
                options={[
                  { label: 'Studio', value: '0' },
                  { label: '1', value: '1' },
                  { label: '2', value: '2' },
                  { label: '3', value: '3' },
                  { label: '4', value: '4' },
                  { label: '5+', value: '5' },
                ]}
              />
            </div>
            <div className='grid grid-cols-1 gap-y-8'>
              <CheckboxField
                divclassname="col-span-1"
                name="privacy"
                label={`By submitting this form, you accept our <a href="/privacy-policy">Privacy Policy</a>`}
              />
              <VisuallyHiddenField>
                <CheckboxField divclassname="col-span-1" name="spam" />
              </VisuallyHiddenField>
              <div className="col-span-1 mt-6">
                <div className="w-full" onClick={() => props.handleSubmit()}>
                  <Button
                    title="Get Rental Estimate"
                    className={`w-full h-12 ${!(props.isValid && props.dirty) && 'pointer-events-none'}`}
                    type="submit"
                    submitting={submitting}
                  />
                </div>
                {errorMessage.length > 0 && (
                  <div className="max-w-md mt-8 text-sm text-error" dangerouslySetInnerHTML={{ __html: errorMessage }} />
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
