import { graphql } from 'gatsby';
import React from 'react';
import Layout from '~/components/Layout';
import FlexibleContent from '~/components/FlexibleContent';
import HeroPricing2 from '~/components/organisms/HeroPricing2';
import Seo from 'gatsby-plugin-wpgraphql-seo';
import CustomSEO from '~/components/seo/CustomSEO';

export default function Formpricing1({ data: { wpPage },  location  }) {
  // console.log('~~~Formpricing1.js');

  const page = wpPage.formpricing1.formpricing1

  //get url parameters
  const params = new URLSearchParams(location.search);
  const postcodeParameter = params.get("postcode");

  return (
    <Layout 
      headerWhite={wpPage.page.page.whiteHeader} 
      backgroundColour={wpPage.page.page.backgroundColour} 
      showLogin={wpPage.page.page.showLogin} 
      footerCtaType={wpPage.page.page.footerCtaType} 
      footerResourcesType={wpPage.page.page.footerResourcesType} 
      pageLanguage={wpPage.page.page.pageLanguage}
    >
      <Seo post={wpPage} />
      <CustomSEO seo={wpPage?.seo} />
      <HeroPricing2 {...page} postcodeParameter={postcodeParameter}  className="!bg-transparent !mb-0 md:!mb-0" />
      <FlexibleContent content={wpPage.formpricing1.formpricing1.content}  />
    </Layout>
  )
}

export const pageQuery = graphql`
  query formpricing2PageQuery($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      id
      ...SEO
      page {
        page {
          backgroundColour
          whiteHeader
          showLogin
          footerCtaType
          footerResourcesType
          pageLanguage
        }
      }
      formpricing1 {
        formpricing1 {
          heroPricing {
            fieldGroupName
            textCard {
              illustration {
                ... Image
              }
              subheading
              subheadingColor
              alignText
              heading {
                text
                type
              }
              content
              buttons {
                button {
                  button {
                    button {
                      ... Button
                    }
                  }
                  type
                }
              }
            }
          }
          content {
            ... on WpPage_Formpricing1_Formpricing1_Content_Faqs {
              fieldGroupName
              faqs {
                fieldGroupName
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText
                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ... Button
                        }
                      }
                      type
                    }
                  }
                }
                faqs {
                  question
                  fieldGroupName
                  answer
                }
                link {
                  ... Button
                }
              }
            }
          }
        }
      }
    }
  }
`
